/*====================================
[  Table of contents  ]
======================================
==> Page Loader
==> Search Button
==> Sticky Header
==> Back To Top
======================================
[ End table content ]
======================================
*/

var script = {};
script.init = function () {
    // (function(jQuery) {
    //     "use strict";
    // jQuery(window).on('load', function(e) {

    jQuery('p:empty').remove();

    /*------------------------
            Page Loader
    --------------------------*/
    // if(!jQuery("#gen-loading").is(':visible'))
    //     jQuery("#gen-loading").fadeIn();
    // jQuery("#gen-loading").delay(1000).fadeOut();
    // jQuery("#gen-loading").delay(1000).fadeOut("slow");

    /*------------------------
            Search Button
    --------------------------*/
    jQuery('#gen-seacrh-btn').on('click', function () {
        jQuery('.gen-search-form').slideToggle();
        jQuery('.gen-search-form').toggleClass('gen-form-show');
        if (jQuery('.gen-search-form').hasClass("gen-form-show")) {
            jQuery(this).html('<i class="fa fa-times"></i>');
        } else {
            jQuery(this).html('<i class="fa fa-search"></i>');
        }
    });

    // jQuery('.gen-account-menu').hide();
    // jQuery('#gen-user-btn').on('click', function (e) {

    //     jQuery('.gen-account-menu').slideToggle();

    //     e.stopPropagation();
    // });

    // jQuery('body').on('click', function () {
    //     if (jQuery('.gen-account-menu').is(":visible")) {
    //         jQuery('.gen-account-menu').slideUp();
    //     }
    // });

    /*------------------------
            Sticky Header
    --------------------------*/
    // var view_width = jQuery(window).width();
    // if (!jQuery('header').hasClass('gen-header-default') && view_width >= 1023) {
    //     var height = jQuery('header').height();
    //     jQuery('.gen-breadcrumb').css('padding-top', height * 1.3);
    // }
    if (jQuery('header').hasClass('gen-header-default')) {
        jQuery(window).scroll(function () {
            var scrollTop = jQuery(window).scrollTop();
            if (scrollTop > 300) {
                jQuery('.gen-bottom-header').addClass('gen-header-sticky animated fadeInDown animate__faster');
            } else {
                jQuery('.gen-bottom-header').removeClass('gen-header-sticky animated fadeInDown animate__faster');
            }
        });
    }
    if (jQuery('header').hasClass('gen-has-sticky')) {
        var lastScrollTop = 0;
        var customHeight = 10;
        var $header = document.querySelector("header");
        jQuery(window).scroll(function () {
            var contentHeight = 300 - ($header.clientHeight + customHeight);
            var scrollTop = jQuery(window).scrollTop() < contentHeight ? jQuery(window).scrollTop() : lastScrollTop + jQuery(window).scrollTop();
            if (scrollTop > 300) {
                lastScrollTop = scrollTop;
                jQuery('header').addClass('gen-header-sticky animated fadeInDown animate__faster');
            } else {
                lastScrollTop = 0;
                jQuery('header').removeClass('gen-header-sticky animated fadeInDown animate__faster');
            }
        });
    }
    /*------------------------
            Back To Top
    --------------------------*/
    jQuery('#back-to-top').fadeOut();
    jQuery(window).on("scroll", function () {
        if (jQuery(this).scrollTop() > 250) {
            jQuery('#back-to-top').fadeIn(1400);
        } else {
            jQuery('#back-to-top').fadeOut(400);
        }
    });
    jQuery('#top').on('click', function () {
        jQuery('top').tooltip('hide');
        jQuery('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    if (jQuery('.tv-show-back-data').length) {
        var url = jQuery('.tv-show-back-data').data('url');
        console.log(url);
        var html = '';
        html += `<div class="tv-single-background">
                <img src="`+ url + `">
            </div>`;
        jQuery('#main').prepend(html);

    }
    //     });
    // })(jQuery);
}, script.loader = function () {
    if (!jQuery("#gen-loading").is(':visible'))
        jQuery("#gen-loading").fadeIn();
    jQuery("#gen-loading").delay(1000).fadeOut();
    jQuery("#gen-loading").delay(1000).fadeOut("slow");
}, script.stickyHeader = function () {
    var view_width = jQuery(window).width();
    if (!jQuery('header').hasClass('gen-header-default') && view_width >= 1023) {
        var height = jQuery('header').height();
        jQuery('.gen-breadcrumb').css('padding-top', height * 1.3);
    }
    if (view_width >= 750) {
        jQuery('#forSmallScreen').hide();
        jQuery('#forLargeScreen').show();
    }
    else {
        jQuery('#forLargeScreen').hide();
        jQuery('#forSmallScreen').show();
    }

}
